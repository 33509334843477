import React from "react"

import Layout from "../components/layout"

const About = ({location}) => (
  <Layout location={location}>
    <div>
      <h1>About us</h1>
      <p>
        Lorem ipsum dolor amet etsy brooklyn single-origin coffee messenger bag
        keytar. Tumblr poke mixtape asymmetrical, williamsburg ethical edison
        bulb. Copper mug distillery readymade bicycle rights master cleanse.
        Twee 8-bit beard, PBR&B unicorn shabby chic blue bottle prism pop-up
        etsy fixie pug wayfarers authentic. Tilde kinfolk irony DIY, pour-over
        quinoa portland meggings hashtag.
      </p>
    </div>
  </Layout>
)

export default About
